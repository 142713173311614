var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "main",
      { staticClass: "footer_page terms_page", attrs: { id: "contents" } },
      [
        _c("div", { staticClass: "set_inner" }, [
          _c("div", { staticClass: "page_ttl_area" }, [
            _c("h1", { staticClass: "page_ttl" }, [_vm._v("About Us")]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }